<template>
	<div>
		<b-card>
			<b-row class="align-items-center">
				<b-col cols="12" md="6" class="mb-1 mb-md-0">
					<b>素材存储空间：</b>
					总空间（{{ capacityInfo.capacity || "0" }}） 已使用（{{
						capacityInfo.used || "0"
					}}） 剩余空间（{{ capacityInfo.available || "0" }}）
				</b-col>
				<b-col
					cols="12"
					md="6"
					class="d-flex align-items-center justify-content-start justify-content-md-end"
				>
					<div class="d-flex align-items-center justify-content-start">
						<b-form-input
							v-model="searchQuery"
							class="d-inline-block mr-1"
							:placeholder="$t('Please Enter...')"
							style="width: 100%"
						/>
						<b-button variant="primary" @click="searchAction">
							<span class="text-nowrap">{{ $t("Search") }}</span>
						</b-button>
					</div>
					<b-button variant="primary" class="ml-1" @click="uploadModalVisible = true">
						<span class="d-none d-sm-inline">{{ $t("Upload Material") }}</span>
						<feather-icon icon="UploadCloudIcon" class="d-inline d-sm-none" />
					</b-button>
					<!-- <uploader></uploader> -->
				</b-col>
			</b-row>
		</b-card>
		<b-tabs v-model="activeTabIndex" @input="tabChange">
			<b-tab :title="$t('All')"></b-tab>
			<b-tab :title="$t('Image')"></b-tab>
			<b-tab :title="$t('Video')"></b-tab>
			<b-tab :title="$t('Text')"></b-tab>
			<b-tab :title="$t('Audio')"></b-tab>
		</b-tabs>
		<!-- 图片/视频 -->
		<b-row v-loading="loading" style="min-height:200px">
			<b-col md="4" xl="3" v-for="(item, index) in dataList" :key="index">
				<b-card class="mb-3">
					<div class="media-box" v-if="item.type==0">
						<img
							:src="fullUrl(item.md5, item.suffix)"
							:alt="item.name + '.' + item.suffix"
						/>
					</div>
					<div class="media-box" v-else-if="item.type==1">
						<video :src="fullUrl(item.md5, item.suffix)"></video>
					</div>
					<div class="media-box" v-else-if="item.type==2">
						<img
							src="@/assets/images/file/text.png"
							:alt="item.name + '.' + item.suffix"
						/>
					</div>
					<div class="media-box" v-else>
						<img
							src="@/assets/images/file/music.png"
							:alt="item.name + '.' + item.suffix"
						/>
					</div>
					<!-- <b-img-lazy v-bind="imgProps" :src="fullUrl(item.md5,item.suffix)" :alt="item.name+'.'+item.suffix"></b-img-lazy> -->
					<div class="d-flex align-items-center pt-2">
						<h5 class="flex-1 mb-0">{{ item.name + "." + item.suffix }}</h5>
						<span class="text-muted">{{ item.created_at }}</span>
					</div>
					<div class="d-flex align-items-center">
						<small class="text-muted flex-1">{{ bytesToSize(item.size) }}</small>
						<b-dropdown variant="link" no-caret right size="sm">
							<template #button-content>
								<feather-icon
									icon="MoreVerticalIcon"
									size="16"
									class="align-middle text-body"
								/>
							</template>
							<b-dropdown-item @click="detailAction(item)">
								<feather-icon icon="InfoIcon" />
								<span class="align-middle ml-50">{{ $t("Details") }}</span>
							</b-dropdown-item>
							<b-dropdown-item @click="previewAction(item)">
								<feather-icon icon="ChromeIcon" />
								<span class="align-middle ml-50">{{ $t("Preview") }}</span>
							</b-dropdown-item>
							<b-dropdown-item @click="renameAction(item)">
								<feather-icon icon="EditIcon" />
								<span class="align-middle ml-50">{{ $t("Rename") }}</span>
							</b-dropdown-item>
							<b-dropdown-item @click="deleteAction(item)">
								<feather-icon icon="Trash2Icon" />
								<span class="align-middle ml-50">{{ $t("Delete") }}</span>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</b-card>
			</b-col>
			<b-col cols="12">
				<div class="no-data" v-if="dataList.length == 0 && !loading">
					<p>暂无相关数据</p>
				</div>
			</b-col>
		</b-row>
		
		<Pagination
			:currentPage="currentPage"
			:pageSize="pageSize"
			:count="dataList.length"
			v-if="dataList.length>0"
		></Pagination>

		<!-- rename  -->
		<rename
			:is-active.sync="renameSidebarVisible"
			:activeData="activeData"
			@refetch-data="getData()"
			v-if="renameSidebarVisible"
		/>
		<!-- upload -->
		<upload
			v-if="uploadModalVisible"
			:is-active.sync="uploadModalVisible"
			@refetch-data="getData()"
		></upload>
	</div>
</template>

<script>
import {
	BCard,
	BCardText,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTabs,
	BTab,
	BDropdown,
	BDropdownItem,
	BImgLazy,
} from "bootstrap-vue";
import Rename from "@/components/material/Rename.vue";
import Upload from "@/components/material/Upload.vue";
import Pagination from "@/components/public/Pagination.vue";
import { getMyAssets, deleteAsset, getCapacity } from "@/libs/apis/serverApi";
import { bytesToSize,setStorage } from "@/libs/utils";
import config from "@/libs/config";

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTabs,
		BTab,
		BDropdown,
		BDropdownItem,
		BImgLazy,

		Rename,
		Upload,
		Pagination
	},
	data() {
		return {
			capacityInfo: {},
			searchQuery: "",
			activeTabIndex: 0,
			renameSidebarVisible: false,
			uploadModalVisible: false,
			activeType: -1,
			loading: false,
			dataList: [],
			pageSize: 12,
			currentPage: 1,
			activeData: null,
			imgProps: {
				center: true,
				blank: true,
				blankColor: "#7367f0",
				height: 400,
			},
		};
	},
	created() {
		getCapacity().then((res) => {
			let { capacity, used } = res;
			capacity = capacity * 1024 * 1024;
			used = +used * 1024 * 1024;
			this.capacityInfo = {
				capacity: bytesToSize(capacity),
				used: bytesToSize(used),
				available: bytesToSize(capacity - used),
			};
		});
		this.getData(1);
	},
	methods: {
		bytesToSize,
		fullUrl(md5, suffix) {
			return config.ossUrl + md5 + "." + suffix;
		},
		getData(page) {
			this.loading = true
			page = page || 1;
			getMyAssets({
				query: this.searchQuery,
				type: this.activeType,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			});
		},
		searchAction() {
			this.getData(1)
		},
		tabChange(index) {
			console.log(index);
			this.activeType = index - 1;
			this.getData(1);
		},
		detailAction (item) {
			setStorage('ACTIVE_MATERIAL', item)
			this.$router.push('/materialsDetail?id='+item.id)
		},
		previewAction(item) {
			setStorage('ACTIVE_MATERIAL', item)
			this.$router.push('/materialsPreview?id='+item.id)
		},
		renameAction(item) {
			this.activeData = item;
			this.renameSidebarVisible = true;
		},
		deleteAction(item) {
			this.$bvModal
				.msgBoxConfirm(this.$t("deleteDataTip"), {
					title: this.$t("Tips"),
					size: "sm",
					okVariant: "danger",
					okTitle: this.$t("Delete"),
					cancelTitle: this.$t("Cancel"),
					cancelVariant: "outline-secondary",
					hideHeaderClose: false,
					centered: true,
				})
				.then((isConfirm) => {
					if (isConfirm) {
						deleteAsset({ id: item.id }).then(
							(res) => {
								this.$toast(this.$t("Delete Success"));
								this.getData(this.currentPage);
							},
							(err) => {
								this.$toast(err.message);
							}
						);
					}
				});
		},
	},
};
</script>

<style lang="scss">
.media-box {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 300px;
	background: rgba(115, 103, 240, 0.05);
	img {
		max-width: 100%;
		max-height: 100%;
	}
	video{
		width: 100%;
		height: 100%;
	}
}
</style>
